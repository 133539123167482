//---------------
// Cookies Page
//---------------

// PACKAGES
import React from "react"

// COMPONENTS
import Sidebar from "../layout/Terms"

// COMPONENT BUILD
class CookiesPage extends React.Component {
    
    // JSX TEMPLATE
    render() {
        return (
            <Sidebar>
                <article className="px-4 py-5 shadow col-lg-10 p-md-6">
                    <h1 className="mb-4">Cookies</h1>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Introduction
                    </h3>
                    <p>1.1 Our website uses cookies.</p>
                    <p>
                        1.2 Insofar as those cookies are not strictly necessary
                        for the provision of Informeasy, we will ask you to
                        consent to our use of cookies when you first visit our
                        website.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Credit
                    </h3>
                    <p>
                        2.1 This document was created using a template from SEQ
                        Legal (
                        <a
                            className="text-break"
                            href="https://www.google.com/policies/privacy/"
                        >
                            https://seqlegal.com
                        </a>
                        ).
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        About cookies
                    </h3>
                    <p>
                        3.1 A cookie is a file containing an identifier (a
                        string of letters and numbers) that is sent by a web
                        server to a web browser and is stored by the browser.
                        The identifier is then sent back to the server each time
                        the browser requests a page from the server.
                    </p>
                    <p>
                        3.2 Cookies may be either "persistent" cookies or
                        "session" cookies: a persistent cookie will be stored by
                        a web browser and will remain valid until its set expiry
                        date, unless deleted by the user before the expiry date;
                        a session cookie, on the other hand, will expire at the
                        end of the user session, when the web browser is closed.
                    </p>
                    <p>
                        3.3 Cookies do not typically contain any information
                        that personally identifies a user, but personal
                        information that we store about you may be linked to the
                        information stored in and obtained from cookies.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        First and third party cookies
                    </h3>
                    <p>
                        4.1 First-party cookies are cookies that belong to
                        Informeasy, third-party cookies are cookies that another
                        party places on your device through our Service.
                        Third-party cookies may be placed on your device by
                        someone providing a service for Informeasy, for example
                        to help us understand how our service is being used.
                        Third-party cookies may also be placed on your device by
                        our business partners so that they can use them to
                        advertise products and services to you elsewhere on the
                        Internet.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Cookies that we use
                    </h3>
                    <p>5.1 We do not use first-party cookies on our website.</p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Cookies that we use
                    </h3>
                    <p>
                        6.1 Our service providers use cookies and those cookies
                        may be stored on your computer when you visit our
                        website.
                    </p>
                    <p>
                        6.2 We use Google Analytics to analyse the use of our
                        website. Google Analytics gathers information about
                        website use by means of cookies. The information
                        gathered relating to our website is used to create
                        reports about the use of our website. Google's privacy
                        policy is available at:{" "}
                        <a
                            href="https://www.google.com/policies/privacy/"
                            className="text-break"
                        >
                            https://www.google.com/policies/privacy/
                        </a>
                        .
                    </p>
                    <p>
                        6.3 We use Facebook Custom Audiences to be able to
                        follow users' actions after they are redirected to our
                        website by clicking on a Facebook advertisement. This
                        helps us to record the effectiveness of our
                        advertisements for statistical and market research
                        purposes. We will not see any of your personal
                        information however, the collected data are saved and
                        processed by Facebook. Your email address will be
                        converted to a unique number that Facebook uses to match
                        to unique numbers Facebook generates from email
                        addresses of its users, in accordance with [Facebook's
                        Data Use Policy]. We may use the collected information
                        to display interest-based ads to you when you are using
                        Facebook. If you would like to opt out of Facebook
                        Custom Audience ads please visit{" "}
                        <a
                            href="https://www.facebook.com/ads/website_custom_audiences/"
                            className="text-break"
                        >
                            https://www.facebook.com/ads/website_custom_audiences/
                        </a>{" "}
                        to revise your preferences.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Managing cookies
                    </h3>
                    <p>
                        7.1 Most browsers allow you to refuse to accept cookies
                        and to delete cookies. The methods for doing so vary
                        from browser to browser, and from version to version.
                        You can however obtain up-to-date information about
                        blocking and deleting cookies via these links:
                    </p>
                    <p>
                        (a){" "}
                        <a
                            href="https://support.google.com/chrome/answer/95647?hl=en"
                            className="text-break"
                        >
                            https://support.google.com/chrome/answer/95647?hl=en
                        </a>{" "}
                        (Chrome);
                    </p>
                    <p>
                        (b){" "}
                        <a
                            href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                            className="text-break"
                        >
                            https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                        </a>{" "}
                        (Firefox);
                    </p>
                    <p>
                        (c){" "}
                        <a
                            href="http://www.opera.com/help/tutorials/security/cookies/"
                            className="text-break"
                        >
                            http://www.opera.com/help/tutorials/security/cookies/
                        </a>{" "}
                        (Opera);
                    </p>
                    <p>
                        (d){" "}
                        <a
                            href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                            className="text-break"
                        >
                            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
                        </a>{" "}
                        (Internet Explorer);
                    </p>
                    <p>
                        (e){" "}
                        <a
                            href="https://support.apple.com/kb/PH21411"
                            className="text-break"
                        >
                            https://support.apple.com/kb/PH21411
                        </a>{" "}
                        (Safari); and
                    </p>
                    <p>
                        (e){" "}
                        <a
                            href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                            className="text-break"
                        >
                            https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
                        </a>{" "}
                        (Edge).
                    </p>
                    <p>
                        7.2 Blocking all cookies will have a negative impact
                        upon the usability of many websites.
                    </p>
                    <h3 className="mt-6 mb-4 header-sans text-primary">
                        Our details
                    </h3>
                    <p>
                        8.1 This website is owned and operated by Tamas Gabor
                        Media Ltd./the partnership of Tamas Gabor and Nora
                        Budavari.
                    </p>
                    <p>
                        8.2 Our principal place of business is at 14 Orchard
                        Street, ME15 6NR Maidstone, United Kingdom.
                    </p>
                    <p>8.3 You can contact us:</p>
                    <p>(a) using our website contact form;</p>
                    <p>(d) by email, using contact@informeasy.com.</p>
                </article>
            </Sidebar>
        )
    }
}

export default CookiesPage
